import React from 'react';

const photos = [
  { src: "photos/bev.jpeg", alt: "hanging out", caption: "hanging out" },
  { src: "photos/8vc_fellowship.jpeg", alt: "8VC engineering fellowship", caption: "8VC engineering fellowship" },
  { src: "photos/brother_match.jpeg", alt: "neurosurgeon brother", caption: "neurosurgeon brother" },
  { src: "photos/family.jpeg", alt: "family time", caption: "family time" },
  { src: "photos/RBC.png", alt: "Royal Bank of Canada Award", caption: "Royal Bank of Canada Award" },
  { src: "photos/nyc_def.jpeg", alt: "co-hosted NYC defense event", caption: "co-hosted NYC defense event" },
  { src: "photos/gundo_def.jpeg", alt: "co-hosted LA defense event", caption: "co-hosted LA defense event" },
  { src: "photos/internship.jpeg", alt: "internship grind", caption: "internship grind" },
  { src: "photos/mayor.jpeg", alt: "Jim Watson (Mayor) supporting my NGO", caption: "Jim Watson (Mayor) supporting my NGO" },
  { src: "photos/hsgrad.jpeg", alt: "HS graduation", caption: "HS graduation" },
  { src: "photos/science_fair.jpeg", alt: "14y/o me presenting research", caption: "14y/o me presenting research" },
  { src: "photos/chillin.jpeg", alt: "chillin", caption: "chillin" },
  { src: "photos/sf-summer.jpeg", alt: "SF summer", caption: "SF summer" },
  { src: "photos/sauron.png", alt: "reconstruction of me and Kevin Hartz in his backyard", caption: "reconstruction of me and Kevin Hartz in his backyard" },
  { src: "photos/china_study.jpeg", alt: "study abroad in China", caption: "study abroad in China" },
  { src: "photos/family_sc.jpeg", alt: "young me", caption: "young me" },
];

function PhotoCollage() {
  return (
    <div className="photo-collage">
      {photos.map((photo, index) => (
        <figure key={index}>
          <img src={photo.src} alt={photo.alt} />
          <figcaption>{photo.caption}</figcaption>
        </figure>
      ))}
    </div>
  );
}

export default PhotoCollage;