import React from 'react';

function Random() {
  return (
    <div>
      <header>
        <h1>random</h1>
      </header>
      <ul>
        <li>I'm a big NBA fan. I also like watching UFC</li>
        <li>once upon a time, I was a trainer for the Caltech Women's Basketball Team</li>
        <li>my brother is a neurosurgeon resident at <a href="https://en.wikipedia.org/wiki/Rush_University_Medical_Center">RUSH Medical Center</a> in Chicago (ranked #3)</li>
        <li>I have visited Mexico, Spain, France, India, China, Italy, UAE, Switzerland</li>
        <li>I used to play the piano & clarinet</li>
        <li>my favorite movies are inception & interstellar</li>
        <li>I listen to all types of music</li>
        <li>I'm clearly not a web developer (and I have 0 aspirations to ever be one)</li>
      </ul>
    </div>
  );
}

export default Random;