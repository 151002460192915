import React from 'react';
import { Link } from 'react-router-dom';

function Header({ name }) {
  return (
    <header>
      <nav aria-label="Additional content">
        <hr align="left" width="40%" style={{ border: '1px dashed white' }} />
        <Link to="/mystory">my-story</Link>
        <hr align="left" width="40%" style={{ border: '1px dashed white' }} />
        <Link to="/random">random</Link>
        <hr align="left" width="40%" style={{ border: '1px dashed white' }} />
        <a href="https://x.com/realAnishGoel" rel="me">x</a>
        <hr align="left" width="40%" style={{ border: '1px dashed white' }} />
        <a href="https://www.linkedin.com/in/anish-goel-caltech/" rel="me">linkedin</a>
        <hr align="left" width="40%" style={{ border: '1px dashed white' }} />
        <a href="https://github.com/anishgoel1" rel="me">github</a>
        <hr align="left" width="40%" style={{ border: '1px dashed white' }} />
        <a href="mailto:anish.goel461@gmail.com" rel="me">contact.anish</a>
        <hr align="left" width="40%" style={{ border: '1px dashed white' }} />
      </nav>
    </header>
  );
}

export default Header;