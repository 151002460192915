import React from 'react';

function MyStory() {
  return (
    <div>
      <header>
        <h1>my story</h1>
      </header>
      <p>I was born & raised in Ottawa, Canada.</p>
      <p>Prior to HS, my brother & I co-founded a non-profit org. At a young age, I worked in homeless shelters & interacted with city officials.</p>
      <p>When I was 14, I worked in <a href="https://www.uottawa.ca/faculty-medicine/leadership-team/dr-daniel-figeys">Dr. Figeys Lab</a> doing Alzheimer's research. I worked with animal models and conducted grad-level proteomics research.</p>
      <p>When I was 16, I worked under <a href="https://www.uottawa.ca/faculty-medicine/dr-richard-naud">Dr. Richard Naud</a> and was fortunate enough to <a href="https://www.sciencedirect.com/science/article/pii/S0306452221003808?via%3Dihub">publish</a> my neuro-AI research.</p>
      <p>I'm now studying at Caltech and my goal is to learn how to build and invest in the most impactful companies.</p>
      <br />
      <header>
        <h1>other interesting experiences:</h1>
      </header>
      <ul>
        <li>I was an <a href="https://www.8vc.com/fellows/anish-goel">8VC engineering fellow</a> and worked under <a href="https://en.wikipedia.org/wiki/Kevin_Hartz">Kevin Hartz</a> on a security startup in SF</li>
        <li>I was a research fellow at Pantera Capital, one of the world's largest institutional crypto hedge funds</li>
        <li>I co-hosted the largest student-led defense tech hackathon with support from the US Department of Defense</li>
      </ul>
      <br />
      <header>
        <h1>what I want to do:</h1>
      </header>
      <ul>
        <li>learn & surround myself with the best</li>
        <li>eventually build something out</li>
        <li>travel, explore, and have fun</li>
      </ul>
    </div>
  );
}

export default MyStory;